import { createContext, useContext } from "react";

export const FileContext = createContext(undefined)

export const useFileContext = () => {
    const { file, setFile } = useContext(FileContext)

    const getFileData = () => {
        if (file === undefined) {
            // throw new Error("The image url should be string!...");
            console.log("Image not present!...");
            return null;
        }
        return file;
    }
    const setFileData = (data: string) => {
        setFile(data)
    }
    return { getFileData, setFileData };
}

